.App {
    text-align: left;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    background-color: #373736;
    position: relative;

    -ms-overflow-style: none;
}

::-webkit-scrollbar {
    display: none;
}

.body {
    display: flex;
    /*overflow-x: auto;*/
    white-space: nowrap;
    /*padding: 10px;*/
}

.gallery-setting {
    width: 100%;
    margin-bottom: 40px;
}
.gallery-setting h1 {
    color: var(--green-white-100);
    font-size: 2rem;
    font-weight: bold;
}